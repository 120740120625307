import React, { useState } from "react";
import { Link } from "gatsby";
import { useSelector, useDispatch } from "react-redux"
import { closeDrawer } from "../../drawers/drawerActions";
// import PropTypes from 'prop-types'



const PrimaryMenu = (props) => {
  const dispatch = useDispatch()
  const [menuOpen, setMenuOpen] = useState( false )



  const linkAction = () => {
    dispatch(closeDrawer())
  }

  return (
    <nav className="navbar navbar-expand-md navbar-light" id="mainnav">
      <div className="container-fluid px-0 px-md-3">
         <Link to="/" className="navbar-brand ps-3">
           {}
            <img src="/img/frl_logo_stacked.svg" className="d-block d-md-none" alt="" role="presentation"/>
            <img src="/img/food-rescue-locator-horizontal-logo.svg" className="d-none d-md-block" alt="" role="presentation"/>
        </Link>
        <button
          className={ menuOpen
           ? 'navbar-toggler opened'
           : 'navbar-toggler'
          }
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setMenuOpen(!menuOpen) }
        >
          <svg width="100" height="100" viewBox="0 0 100 100">
            <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
            <path className="line line2" d="M 20,50 H 80" />
            <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
          </svg>
        </button>
        <div
          className={ menuOpen
          ? 'collapsed navbar-collapse justify-content-md-end opened'
          : 'collapse navbar-collapse justify-content-md-end'
          }
          id="navbarNav"
        >
          <ul className="navbar-nav">
            <li className="nav-item" onClick={() => linkAction()}>
              <Link to="/" className="nav-link" activeClassName="active">
                Search Locator
              </Link>
            </li>
            <li className="nav-item" onClick={() => linkAction()}>
              <Link to="/add-organization" className="nav-link" activeClassName="active">
                Add Organization
              </Link>
            </li>
            <li className="nav-item" onClick={() => linkAction()}>
              <Link to="/resources" className="nav-link" activeClassName="active">
                Resources
              </Link>
            </li>
            <li className="nav-item" onClick={() => linkAction()}>
                <img src="/img/sa_presented_by_logo.svg" alt="" role="presentation"/>
            </li>
          </ul>
        </div>

      </div>
    </nav>
  );
};


PrimaryMenu.propTypes = {};

export default PrimaryMenu;

import React from 'react'
import Layout from '../containers/Layout'

const resources = () => {
  return (
    <Layout>
      <div className='section'>
        <div className='container pt-4'>
          <div className='row'>
            <div className='col-12 text-center'>
              <h1>About</h1>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default resources

export const Head = () => (
  <>
    <title>About | Food Rescue Locator</title>
  </>
)

import React from "react";

const FooterMenu = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-4 px-md-5">
          <h5>Need Food Assistance?</h5>
          <p>Use the USDA Hunger Hotline: <strong><a href="tel:1-866-348-6479">1.866.3.HUNGRY</a></strong></p>
          <p>The Food Rescue Locator is built and maintained by Sustainable America, with support and guidance from the Food Rescue Alliance, Environmental Protection Agency and the Harvard Law School Food Law and Policy Clinic.</p>
        </div>
        <div className="col-12 col-md-4 border-start border-end px-md-5 my-4 my-md-0">
          <h5>Brought To You By</h5>
          <a href="https://sustainableamerica.org/" target="_blank">
            <img className="" src="/img/sa_logo.svg" alt="Sustainable America" />
          </a>
        </div>
        <div className="col-12 col-md-4 px-md-5">
          <h5>In Partnership With</h5>
          <a href="https://www.foodrescuealliance.org/" target="_blank">
            <img className="m-3 img-fluid" src="/img/fra-logo.svg" alt="Food Resscue Alliance" />
          </a>
          <a href="https://www.epa.gov/" target="_blank">
            <img className="m-3 img-fluid" src="/img/epa-logo.svg" alt="EPA" />
          </a>
          <a href="https://www.chlpi.org/" target="_blank">
            <img className="m-3 img-fluid" width="360px" src="/img/chlpi-logo.png" alt="Center for Health Law and Policy Innovation" />
          </a>
          <br />
          <a href="https://www.foodrecoverynetwork.org/" target="_blank">
            <img className="m-3 img-fluid" width="200px" src="/img/frn-logo.png" alt="Food Recovery Network" />
          </a>
          <a href="https://foodsystemscollaborative.com/" target="_blank">
            <img className="m-3 img-fluid" width="200px" src="/img/fsc-logo.png" alt="Food Systems Collaborative" />
          </a>
        </div>
      </div>
    </div>
  );
};

FooterMenu.propTypes = {};

export default FooterMenu;
